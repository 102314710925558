// 独立方法,独立引用

/*
 * 判断obj是否为一个整数
 */
function isInteger(obj) {
    return Math.floor(obj) === obj
}

/*
 * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
 * @param floatNum {number} 小数
 * @return {object}
 *   {times:100, num: 314}
 */
function toInteger(floatNum) {
    var ret = {
        times: 1,
        num: 0
    };
    if (isInteger(floatNum)) {
        ret.num = floatNum;
        return ret
    }
    var strfi = floatNum + ''; //转字符串
    var dotPos = strfi.indexOf('.'); //获取小数点下标
    var len = strfi.substr(dotPos + 1).length; //小数点后有几位
    var times = Math.pow(10, len); //10的len次方
    var intNum = parseInt(floatNum * times + 0.5, 10);
    ret.times = times;
    ret.num = intNum;
    return ret
}

/*
 * 核心方法，实现加减乘除运算，确保不丢失精度
 * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
 *
 * @param a {number} 运算数1
 * @param b {number} 运算数2
 * @param op {string} 运算类型，有加减乘除（add/sub/mul/divide）
 *
 */
function operation(a, b, op) {
    var o1 = toInteger(a);
    var o2 = toInteger(b);
    var n1 = o1.num;
    var n2 = o2.num;
    var t1 = o1.times;
    var t2 = o2.times;
    var max = t1 > t2 ? t1 : t2;
    var result = null;
    switch (op) {
        case 'add': //加法
            if (t1 === t2) { // 两个小数位数相同
                result = n1 + n2
            } else if (t1 > t2) { // o1 小数位 大于 o2
                result = n1 + n2 * (t1 / t2)
            } else { // o1 小数位 小于 o2
                result = n1 * (t2 / t1) + n2
            }
            return result / max;
        case 'sub': //减法
            if (t1 === t2) {
                result = n1 - n2
            } else if (t1 > t2) {
                result = n1 - n2 * (t1 / t2)
            } else {
                result = n1 * (t2 / t1) - n2
            }
            return result / max;
        case 'mul': //乘法
            result = (n1 * n2) / (t1 * t2);
            return result;
        case 'divide': //除法
            result = (n1 / n2) * (t2 / t1);
            return result
    }
}

// 加 
function add(a, b) {
    return operation(a, b, 'add')
}
//减
function sub(a, b) {
    return operation(a, b, 'sub')
}
//乘
function mul(a, b) {
    return operation(a, b, 'mul')
}
//除
function divide(a, b) {
    return operation(a, b, 'divide')
}
// 四合五入  ()
function mathRound(number) {
    return Math.round(number * 100) / 100 // 成功转成1.54
}
// 保留几位小数    (偶尔会出现没有四舍五入的问题,例如谷歌浏览器下 1.535 不会四舍五入,1.635.toFixed(2)可以)
function mathToFixed(number, num) {
    return number.toFixed(num) // 成功转成1.54
}


export default {
    mathToFixed,
    mathRound,
    divide,
    mul,
    sub,
    add
}