<template>
  <!-- 收款核销页面 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1 wtl_btnList" ref="btnList">
      <div class="btnList1 wtl_btnList1">
        <el-button
          v-if="hasPermiss('ysgl_shouKuanHeXiao:write')"
          class="itemBtn btnColor"
          @click="writeOff()"
          >核销</el-button
        >
        <el-button
          v-if="hasPermiss('ysgl_shouKuanHeXiao:write:return')"
          class="itemBtn btnColor"
          @click="cancelWriteOff()"
          >返核销</el-button
        >
        <el-button class="itemBtn btnColor" @click="Urge">催款</el-button>
        <el-button class="itemBtn btnColor" @click="yeWriteOff"
          >会员余额核销</el-button
        >
        <!-- <el-button class="itemBtn btnColor" @click="packDetails"
        >打包单详情</el-button
      > -->
        <!-- <el-button class="itemBtn btnColor">费用重算</el-button>
      <el-button class="itemBtn btnColor">费项修改</el-button>
      <el-button class="itemBtn btnColor">催款</el-button>
      <el-button class="itemBtn btnColor">催款</el-button> -->
        <el-button class="itemBtn btnColor" @click="myModel"
          >列表设置</el-button
        >
        <el-dropdown @command="handleCommand_dc">
          <el-button class="dropdownBtn">
            导出<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="dc">导出</el-dropdown-item>
            <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
            <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div
        class="singleinpbtn wtl_queryBoxNew"
        v-if="hasPermiss('ysgl_shouKuanHeXiao:write:query')"
      >
        <el-button class="seniorbut" @click="queryBtn_ok" icon="el-icon-search"
          >查询</el-button
        >
        <el-tooltip content="查询项恢复初始状态" placement="top">
          <div class="condition" @click="queryBtn_cz">清除</div>
        </el-tooltip>
        <el-tooltip content="查询更多条件" placement="top">
          <div class="condition" @click="more">
            {{ MoreConditions }}
          </div>
        </el-tooltip>
      </div>
    </div>
    <!-- 查询条件框 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <multitime
            :timeType="timeType"
            :timeTypes="timeTypes"
            :startTime="startTime_a"
            :endTime="endTime_a"
            @timestatus="timestatus"
            @queryBtn_ok="queryBtn_time"
          >
          </multitime>

          <div class="singleinp">
            <div class="singleinpleft">来源单号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.documentNumber"
                placeholder="请输入内容"
                clearable
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">申请打包编号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.billpackNumber"
                placeholder="请输入内容"
                clearable
                @keyup.enter.native="queryBtn_ok"
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.memberId"
                placeholder="请输入内容"
                clearable
                @keyup.enter.native="queryBtn_ok"
              ></el-input>
            </div>
          </div>

          <div class="singleinp">
            <div class="singleinpleft">核销状态:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.writeoffStatus"
                filterable
                placeholder="请选择"
                clearable
                @change="changeWOffStatus"
              >
                <el-option
                  v-for="item in writeoffStatusList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="singleinp">
            <div class="singleinpleft">仓库:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.storageId"
                filterable
                placeholder="请选择"
                clearable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in WarehouseList"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">结算对象类型:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.objectStatus"
                filterable
                placeholder="请选择"
                clearable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in objectStatusList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">单据类型:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.documentStatus"
                filterable
                placeholder="请选择"
                clearable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in documentStatusList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <!-- <div class="singleinp_time">
            <div class="timeBox queryItemTime">
              <div class="right_inpu">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="time_second"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="核销起始日期"
                  end-placeholder="核销结束日期"
                  @change="changTimes"
                >
                </el-date-picker>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </el-form>

    <!-- 表格 -->
    <div class="tableBox" ref="tableWrapper">
      <el-table
        :height="tableHeigth"
        :row-class-name="tableRowClassName"
        highlight-current-row
        ref="mytable"
        @row-click="rowclick"
        :data="tableData"
        border
        :header-cell-style="{ background: '#f0f0f0' }"
        show-summary
        :summary-method="getSummaries"
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed="left" label="序号" type="index" width="60">
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="60">
        </el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            sortable
            :label="item.name"
            :prop="item.field"
            :width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>
      </el-table>
    </div>
    <!-- //排序 -->
    <sorttable
      :show_sortTableHeard="show_sortTableHeard"
      :allCheck="allCheck"
      :sortName="sortName"
      :tabelHeadeTitle="tabelHeadeTitle"
      :selectLength="selectLength"
      @cancelSortHeard="cancelSortHeard"
      @confirmOk="confirmOk"
      @checktab="checktab"
      @checkAll="checkAll"
      :originalTabelHeadeTitle="originalTabelHeadeTitle"
      @closeDias="closeDias"
    ></sorttable>
    <div></div>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>
    <!-- 上传编辑好的导出模板 -->
    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 收款确认 -->
    <div>
      <el-dialog
        title="收款确认"
        :visible.sync="show_writeOff"
        :modal-append-to-body="false"
        width="40%"
        center
        @close="closeDiawriteOff"
      >
        <div>
          <el-form
            label-position="right"
            label-width="80px"
            :model="formLabelAlign"
          >
            <el-form-item label="收款金额">
              <el-input
                readonly
                v-model="formLabelAlign.amountCollected"
              ></el-input>
            </el-form-item>
            <el-form-item label="资金账户">
              <el-select
                v-model="formLabelAlign.accountId"
                filterable
                clearable
                placeholder="请选择账户类型"
                @change="changeAccountId"
              >
                <el-option
                  v-for="(item, idx) in capitalAccountList"
                  :key="idx"
                  :label="item.showTitle"
                  :value="item.accountId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="核销时间">
              <el-date-picker
                style="width: 100%"
                class="input"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="formLabelAlign.writeOffTime"
                type="datetime"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="上传图片">
              <el-upload
                :action="uploadUrl"
                :http-request="uploadSectionImg"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :file-list="fileListImg"
                :limit="fileNum"
              >
                <i v-if="fileListImg.length == 0" class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <!-- <el-form-item label="账户类型">
              <el-select
                v-model="formLabelAlign.accountStatus"
                placeholder="请选择账户类型"
              >
                <el-option
                  v-for="(item, idx) in account_status_list"
                  :key="idx"
                  :label="item.showName"
                  :value="item.statusVal"
                ></el-option>
              </el-select>
            </el-form-item> -->
            <!-- <el-form-item label="资金账户">
              <el-input v-model="formLabelAlign.accountId"></el-input>
            </el-form-item> -->
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button class="saveBtn" @click="saveWriteOff">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 会员余额核销 -->
    <div>
      <el-dialog
        title="会员余额核销确认"
        :visible.sync="show_writeOff_ye"
        :modal-append-to-body="false"
        width="40%"
        center
        @close="closeDiawriteOff_ye"
      >
        <div>
          <el-form
            label-position="right"
            label-width="80px"
            :model="formDataYuE"
            :rules="rulesYe"
          >
            <el-form-item label="会员号:">
              <el-input readonly v-model="formDataYuE.memberNamse"></el-input>
            </el-form-item>
            <el-form-item label="会员余额">
              <el-input readonly v-model="formDataYuE.memberMoney"></el-input>
            </el-form-item>
            <el-form-item label="核销金额" prop="writeOffMoney">
              <el-input v-model="formDataYuE.writeOffMoney"></el-input>
            </el-form-item>
            <el-form-item label="核销时间" prop="writeOffTime">
              <el-date-picker
                style="width: 100%"
                class="input"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="formDataYuE.writeOffTime"
                type="datetime"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="备注" prop="comment">
              <el-input
                teye="textarea"
                v-model="formDataYuE.comment"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传图片">
              <el-upload
                :action="uploadUrl"
                :http-request="uploadSectionImg"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :file-list="fileListImg_ye"
                :limit="fileNum"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <!-- <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog> -->
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button class="saveBtn" @click="saveWriteOff_ye">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 返核销 -->
    <div>
      <el-dialog
        title="返核销确认"
        :visible.sync="show_writeOff_fhx"
        :modal-append-to-body="false"
        width="45%"
        center
        @close="closeDiawriteOff_fhx"
      >
        <div>
          <el-form
            label-position="right"
            label-width="120px"
            :model="formDataFHX"
            :rules="rulesYe"
          >
            <el-form-item label="付款方式">
              <el-select
                v-model="formDataFHX.paymentMethod"
                placeholder="请选择账户类型"
                @change="changeRway_fhx"
                filterable
                clearable
              >
                <el-option
                  v-for="(item, idx) in payment_method_list"
                  :key="idx"
                  :label="item.showName"
                  :value="item.statusVal"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收款总金额">
              <el-input
                readonly
                v-model.trim="formDataFHX.memberMoney"
              ></el-input>
            </el-form-item>

            <!-- <el-form-item label="资金账户">
              <el-select
                v-model="formDataFHX.accountId"
                filterable
                clearable
                placeholder="请选择退款账号"
                @change="changeAccountId_FHX"
              >
                <el-option
                  v-for="(item, idx) in capitalAccountList"
                  :key="idx"
                  :label="item.showTitle"
                  :value="item.accountId"
                ></el-option>
              </el-select>
            </el-form-item> -->
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button class="saveBtn" @click="saveWriteOff_fsh"
            >取消收款</el-button
          >
        </div>
      </el-dialog>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";
import mathjs from "@/assets/js/MathNumBer";
import sorttable from "@/components/sortTemplate.vue";
import multitime from "@/components/multiTime.vue";
export default {
  inject: ["reload"],
  components: {
    // mytables,
    draggable,
    sorttable,
    paging,
    multitime,
  },
  data() {
    return {
      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      queryData: {
        billPackNumber: "", //打包申请编号
        writeoffEnd: "", //核销结束时间
        writeoffStart: "", //核销开始时间
        documentNumber: "", //单据编号
        documentStatus: "", //单据类型
        memberId: "", //会员号
        objectStatus: "", //结算对象类型
        storageId: "",
        writeoffStatus: "wei_he_xiao:writeoff_status",
      },
      time_second: [],
      show_writeOff: false, //收款确认弹窗
      show_writeOff_ye: false, //会员余额收款确认弹窗
      show_writeOff_fhx: false, //返核销确认弹窗

      // ======公用组件==========
      pageNum: 1, //页码
      total: 0,
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "单据类型",
          field: "documentStatusShow",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "来源单号",
          field: "documentNumber",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "申请打包编号",
          field: "billpackNumber",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "仓库名称",
          field: "storageName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员号",
          field: "memberId",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "资金账号",
          field: "zjAccount",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "结算金额",
          field: "settlementAmount",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "会员余额",
          field: "curAmount",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "付款方式",
          field: "paymentMethodShow",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "核销状态",
          field: "writeoffStatusShow",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        // {
        //   name: "审批类型",
        //   field: "approveStatusShow",
        //   width: "120",
        //   sort: 2,
        //   isShow: true,
        //   isTotal: false,
        //   isSort: true,
        // },
        {
          name: "未付款天数",
          field: "unpaidDays",
          width: "120",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        // {
        //   name: "审核状态",
        //   field: "approveStatusShow",
        //   width: "120",
        //   sort: 4,
        //   isShow: true,
        //   isTotal: false,
        //   isSort: true,
        // },
        {
          name: "备注",
          field: "comment",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "创建时间",
          field: "createTime",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "核销操作时间",
          field: "writeoffTime",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "申请人",
          field: "objectUserName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "审核时间",
          field: "approveTime",
          width: "120",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "审核人",
          field: "approverUserName",
          width: "120",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "操作时间",
          field: "operationTime",
          width: "160",
          sort: 2,
          isShow: false,
          isTotal: false,
          isSort: true,
        },

        {
          name: "结算对象类型",
          field: "objectStatusShow",
          width: "120",
          sort: 4,
          isShow: false,
          isTotal: false,
          isSort: true,
        },

        {
          name: "核销操作人",
          field: "writeoffApproverUserName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        // {
        //   name: "操作人",
        //   field: "operatorUserName",
        //   width: "120",
        //   sort: 4,
        //   isShow: true,
        //   isTotal: false,
        //   isSort: true,
        // },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "writeOff", //页面标识

      writeoffStatusList: [], //核销状态
      approveStatusList: [], //审核状态类别
      WarehouseList: [], //仓库下拉列表
      objectStatusList: [], //结算对象类型
      documentStatusList: [], //单据类型
      account_status_list: [], //资金账号类型
      payment_method_list: [], //付款类型
      capitalAccountList: [], //资金账号下拉列表
      ids: [], //选中的数据
      formLabelAlign: {
        amountCollected: "", //收款金额
        accountStatus: "", //账户类型
        accountId: "", //资金账户id
        writeOffTime: "", //
        writeOffPic: "", //
        ids: [], //应收计费id数组
      },
      changList: [], //当前选中的行数据
      fileListImg: [], //核销图片
      fileListImg_ye: [], //余额核销图片
      fileNum: 6,
      dialogVisible: false, //
      dialogImageUrl: "",
      AllwriteOffPic: [],
      formDataYuE: {
        comment: "",
        ids: [],
        writeOffPic: "",
        writeOffTime: "",
        memberNamse: "",
        memberMoney: "", //余额
        writeOffMoney: "", //核销金额
        accountId: "", //
      },
      rulesYe: {
        writeOffMoney: [
          { required: true, message: "请输入核销金额", trigger: "blur" },
        ],
        writeOffTime: [
          { required: true, message: "请选择核销时间", trigger: "blur" },
        ],
        accountId: [
          { required: true, message: "请选择资金账号", trigger: "change" },
        ],
        comment: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
      formDataFHX: {},
      fhxIds: [],

      // 时间组件
      timeTypes: [
        //  时间类型
        {
          value: "0",
          label: "审核时间",
        },
      ],
      timeType: "0", //  时间类型
      startTime_a: "",
      endTime_a: "",
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.getGroupFindFieldSort(); //获取用户自定义表头
    // this.myTableHeard = this.tabelHeadeTitle;

    this.endTime_a = tools.getCurrentDate(); //今天的时间;
    this.startTime_a = tools.getFlexDate(-10); //10天前
    // this.queryData.writeoffEnd = tools.getCurrentDate() + " 23:59:59"; //今天的时间;
    // this.queryData.writeoffStart = tools.getFlexDate(-10) + " 00:00:00"; //10天前

    this.getStatusValList("finance_in_record_v1.approve_status"); //审核状态
    this.getStatusValList("finance_in_record_v1.object_status"); //结算对象类型
    this.getStatusValList("finance_in_record_v1.document_status"); //单据类型
    this.getStatusValList("finance_in_record_v1.writeoff_status"); //核销状态
    this.getStatusValList("finance_assetaccount_v1.account_status"); //资金账户类型
    this.getStatusValList("finance_in_record_v1.payment_method"); //应收付款方式
  },
  mounted() {
    const that = this;

    this.getData();
  },
  watch: {},
  methods: {
    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);
    },
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    // 时间组件方法
    queryBtn_time(s, e) {
      this.startTime_a = s || "";
      this.endTime_a = e || "";
    },
    timestatus(type, s, e) {
      this.timeType = type || "0";
      this.startTime_a = s || "";
      this.endTime_a = e || "";
    },
    //确认收款弹窗关闭
    closeDiawriteOff() {
      this.formLabelAlign = {
        amountCollected: "", //收款金额
        accountStatus: "", //账户类型
        accountId: "", //资金账户id
        ids: [], //应收计费id数组
        writeOffTime: "", //
        writeOffPic: "", //
      };
      this.fileListImg = [];
      this.AllwriteOffPic = [];
    },
    //确认收款弹窗关闭
    closeDiawriteOff_ye() {
      this.formDataYuE = {
        comment: "",
        ids: [],
        writeOffPic: "",
        writeOffTime: "",
        memberNamse: "",
        memberMoney: "",
        writeOffMoney: "",
        accountId: "",
      };
      this.fileListImg_ye = [];
      this.AllwriteOffPic = [];
    },
    //确认收款弹窗关闭
    closeDiawriteOff_fhx() {
      this.formDataFHX = {};
      this.fileListImg_ye = [];
      this.AllwriteOffPic = [];
    },
    //资金账户下拉列表
    getCapitalAccountList() {
      Api.capitalAccountList().then((res) => {
        if (res.data.status == "success") {
          let capitalAccountList = res.data.result || [];
          for (var i = 0; i < capitalAccountList.length; i++) {
            capitalAccountList[i].showTitle =
              capitalAccountList[i].accountName +
              " " +
              capitalAccountList[i].account +
              " ";
            for (var j = 0; j < this.account_status_list.length; j++) {
              if (
                capitalAccountList[i].accountStatus ==
                this.account_status_list[j].statusVal
              ) {
                let staVal = this.account_status_list[j].showName;
                capitalAccountList[i].showTitle += staVal;
              }
            }
          }
          this.capitalAccountList = capitalAccountList;
        }
      });
    },
    //选择资金账户
    changeAccountId(e) {
      ////console.log(e);
      let id = e;
      let arrs = this.capitalAccountList || [];
      for (var i = 0; i < arrs.length; i++) {
        if (arrs[i].accountId == id) {
          this.formLabelAlign.accountStatus = arrs[i].accountStatus;
        }
      }
    },

    //返核销选择退回方式
    changeRway_fhx(e) {},
    //返核销选择资金账号
    changeAccountId_FHX(e) {},
    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge == false) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },
    // 获取状态展示值
    getStatusValList(status) {
      var dataArr = [];
      // 包裹状态: ==========> package_v1.package_status
      // 拒收单拒收状态: ====> bill_rejection_v1.handle_status
      // 验货单验货状态: ====> bill_inspection_v1.check_status
      // 审批状态:==========> finance_in_record_v1.approve_status
      // 核销状态:==========> finance_in_record_v1.writeoff_status
      // 单据类型:==========> finance_in_record_v1.document_status
      // 打包状态:==========> bill_pack_v1.pack_status
      let param = {
        tableAndFieldName: status,
      };

      Api.getStatusValList(param).then((res) => {
        // 审核状态
        if (status == "finance_in_record_v1.approve_status") {
          let dataArr = res.data.result || [];
          this.approveStatusList = dataArr;
        }
        // 结算对象类型
        if (status == "finance_in_record_v1.object_status") {
          let objectStatusList = res.data.result || [];
          this.objectStatusList = objectStatusList;
        }
        // 单价类型
        if (status == "finance_in_record_v1.document_status") {
          let documentStatusList = res.data.result || [];
          this.documentStatusList = documentStatusList;
        }
        // 核销状态
        if (status == "finance_in_record_v1.writeoff_status") {
          let writeoffStatusList = res.data.result || [];
          this.writeoffStatusList = writeoffStatusList;
        }
        // 资金账户类型
        if (status == "finance_assetaccount_v1.account_status") {
          let account_status_list = res.data.result || [];
          this.account_status_list = account_status_list;
        }
        // 资金账户类型
        if (status == "finance_in_record_v1.payment_method") {
          let payment_method_list = res.data.result || [];
          this.payment_method_list = payment_method_list;
        }
      });
    },
    // //获取仓库下拉列表
    getStorageList() {
      Api.getStorageList().then((res) => {
        //////console.log(res.data.result)
        let cangkuList = res.data.result || [];
        this.WarehouseList = cangkuList;
      });
    },
    //催款
    Urge() {
      let arr = this.changList || [];
      if (arr.length < 1) {
        this.$message.warning("至少选择一条数据");
        return;
      } else {
        this.$confirm("是否确定发送催款信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let idList = new Array();
          for (var i = 0; i < arr.length; i++) {
            idList.push(arr[i].financeInId);
          }
          let param = new Object();
          param.financeInId = idList;
          let sign = tools.getSign(param);
          param.sign = sign;
          Api.pressForMoney(param).then((res) => {
            if (res.data.status == "success") {
              this.$message.success(res.data.message || "催款发起成功");
              this.getData();
            }
          });
        });
      }
    },
    // 仓库打包单详情
    packDetails() {},

    // 余额核销确认
    saveWriteOff_ye() {
      let param = {
        comment: this.formDataYuE.comment, //
        ids: this.formDataYuE.ids || [], //
        writeOffPic: this.AllwriteOffPic || [], //
        writeOffTime: this.formDataYuE.writeOffTime, //
        // accountId: this.formDataYuE.accountId, //
      };
      let sign = tools.getSign(param);
      param.sign = sign;
      Api.memberAccountWriteOff(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.show_writeOff_ye = false;
        this.getData();
      });
    },
    //确定核销
    saveWriteOff() {
      // let param = this.formLabelAlign;
      // param.ids = param.ids;
      ////console.log(param);
      let param = {
        amountCollected: this.formLabelAlign.amountCollected, //收款金额
        accountStatus: this.formLabelAlign.accountStatus, //账户类型
        accountId: this.formLabelAlign.accountId, //资金账户id
        writeOffTime: this.formLabelAlign.writeOffTime, //
        writeOffPic: this.AllwriteOffPic || [], //
        ids: this.formLabelAlign.ids || [], //应收计费id数组
      };
      Api.writeOff(param).then((res) => {
        if (res.data.status == "success") {
          this.$message.success(res.data.message || "操作成功");
          this.show_writeOff = false;
          this.getData();
        }
      });
    },
    // 会员余额核销按钮
    yeWriteOff() {
      let arr = this.changList || [];
      if (arr.length < 1) {
        this.$message.warning("至少选择一条数据");
        return;
      }
      var idList = [];
      var count = 0; //核销金额
      var count2 = 0; //会员余额
      console.log(arr);
      console.log("arr");
      let memberId = arr[0].memberId;
      for (var i = 0; i < arr.length; i++) {
        if (memberId !== arr[i].memberId) {
          this.$message.warning("所选应付单存在多个会员,不能核销");
          return false;
        }
        idList.push(arr[i].financeInId);
        count = mathjs.add(count, arr[i].settlementAmount); //精度计算 核销金额
        count2 = mathjs.add(count2, arr[i].curAmount); //精度计算  会员余额
      }
      this.formDataYuE.memberNamse = arr[0].memberId + " " + arr[0].memberName;
      this.formDataYuE.writeOffMoney = count || 0;
      // this.formDataYuE.memberMoney = count2 || 0;//取消余额累加
      this.formDataYuE.memberMoney = JSON.parse(
        JSON.stringify(arr[0].curAmount)
      );
      this.formDataYuE.ids = idList;
      this.show_writeOff_ye = true;
      this.getCapitalAccountList(); //获取资金账户下拉列表
    },
    // 核销按钮
    writeOff() {
      let arr = this.changList || [];
      if (arr.length < 1) {
        this.$message.warning("至少选择一条数据");
        return;
      }
      var idList = [];
      var count = 0;
      for (var i = 0; i < arr.length; i++) {
        idList.push(arr[i].financeInId);
        count = mathjs.add(count, arr[i].settlementAmount); //精度计算
      }
      this.getCapitalAccountList(); //获取资金账户下拉列表
      this.formLabelAlign.ids = idList;
      this.formLabelAlign.amountCollected = count;
      this.show_writeOff = true;
    },
    //返核销 (按钮)
    cancelWriteOff() {
      let arr = this.changList || [];
      if (arr.length < 1) {
        this.$message.warning("至少选择一条数据");
        return;
      }

      let payType = arr[0].paymentMethod;
      let allMoney = 0;
      for (var a = 0; a < arr.length; a++) {
        allMoney = tools.add(allMoney, arr[a].settlementAmount);
        if (arr[a].paymentMethod != payType) {
          this.$message.warning("所选返核销的数据支付方式不一致,请重新选择");
          return false;
        }
        if (arr[0].writeoffStatus == "wei_he_xiao:writeoff_status") {
          this.$message.warning("所选数据存在未核销项");
          return false;
        }
      }
      let idList = [];
      for (var i = 0; i < arr.length; i++) {
        idList.push(arr[i].financeInId);
      }
      this.fhxIds = idList;
      if (payType == "hui_yuan_yu_e_pay:payment_method") {
        this.$confirm("是否要返核销" + arr.length + " 条数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            Api.cancelWriteOff({
              ids: idList,
              paymentMethod: "", //会员余额
            }).then((res) => {
              if (res.data.status == "success") {
                this.$message.success(res.data.message || "操作成功");
                this.getData();
              }
            });
          })
          .catch(() => {});
      } else {
        console.log(allMoney);
        console.log("allMoney");
        this.formDataFHX.memberMoney = allMoney;
        this.show_writeOff_fhx = true;
        return false;
      }
    },
    // 返核销 (除了核销方式是会员余额的)
    saveWriteOff_fsh() {
      Api.cancelWriteOff({
        ids: this.fhxIds,
        paymentMethod: this.formDataFHX.paymentMethod, //会员余额
      }).then((res) => {
        if (res.data.status == "success") {
          this.$message.success(res.data.message || "操作成功");
          this.show_writeOff_fhx = false;
          this.getData();
        }
      });
    },
    //上传图片
    uploadSectionImg(param) {
      ////console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            // let fileDatas = res.data.result || '';
            let fileName = res.data.result.fileName;
            this.AllwriteOffPic.push(fileName);
          }
        })
        .catch((err) => {});
    },
    // 删除图片
    handleRemove(flie, fileList) {
      this.AllwriteOffPic = [];
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },
    // 上传导出模板
    uploadSectionFile(param) {
      ////console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "付款核销列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let btnListHeight = this.$refs.btnList.offsetHeight; // 按钮列表
      let pagsHeigth = 40; //分页组件
      let heardHeight = 60; //头部(黑色部分)
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + heardHeight;
      return Math.floor(windonHeight - otherHeight - 20) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      //   let formData = this.queryData;
      let that = this;
      let dN = this.queryData.documentNumber || [];
      if (dN) {
        dN = tools.getOrderNum(dN);
        // dN = JSON.stringify(dN);
      }
      // 处理申请打包编号为数组
      let bK = this.queryData.billpackNumber || [];
      if (bK) {
        bK = tools.getOrderNum(bK);
        // dN = JSON.stringify(dN);
      }

      let writeoffStart = "";
      let writeoffEnd = "";
      if (this.startTime_a) {
        writeoffStart = this.startTime_a + " 00:00:00";
      }
      if (this.endTime_a) {
        writeoffStart = this.endTime_a + " 23:59:59";
      }
      let formData = {
        pageStart: that.pageNum, //页码
        pageTotal: that.size, //每页条数
        writeoffEnd: writeoffEnd, //结束审核时间
        writeoffStart: writeoffStart, //起始审核时间
        billPackNumber: bK, //申请打包编号
        documentNumber: dN, //单据编号(表的编号)
        documentStatus: that.queryData.documentStatus, //单据类型(<表名>:document_status)
        memberId: that.queryData.memberId, //会员号
        objectStatus: that.queryData.objectStatus, //结算对象类型 hui_yuan:object_status=会员对象
        storageId: that.queryData.storageId, //仓库id
        writeoffStatus: that.queryData.writeoffStatus, //核销状态 wei_he_xiao:writeoff_status=未核销 yi_he_xiao:writeoff_status=已核销
      };

      Api.collectionWriteOffList(formData).then((res) => {
        if (res.data.status == "success") {
          that.className = res.data.result.className || "";
          let tdata = [];
          if (res.data.result) {
            tdata = res.data.result.data || [];
            for (var i = 0; i < tdata.length; i++) {
              if (tdata[i].writeoffStatus == "yi_he_xiao:writeoff_status") {
                //已核销
                tdata[i].unpaidDays = "--";
              }
            }
          }
          if (tdata.length > 0) {
            tdata = JSON.parse(JSON.stringify(tdata));
          }
          that.tableData = tdata;
          that.total = res.data.result.pageCount || 0;
          this.fetTableHeight();
        }
      });
    },
    // 查询按钮
    queryBtn_ok() {
      this.pageNum = 1;
      this.getData();
    },
    changeWOffStatus(e) {
      console.log(e);
      if (e == "wei_he_xiao:writeoff_status") {
        this.time_second = [];
      }
      this.queryBtn_ok();
    },
    changTimes(e) {
      console.log(e);
      if (e) {
        this.queryData.writeoffStatus = "yi_he_xiao:writeoff_status";
      } else {
        this.time_second = [];
      }
      this.queryBtn_ok();
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {
        writeoffEnd: null, //核销结束时间
        writeoffStart: null, //核销开始时间
        documentNumber: null, //单据编号
        billPackNumber: null, //打包申请编号
        documentStatus: null, //单据类型
        memberId: null, //会员号
        objectStatus: null, //结算对象类型
        storageId: null,
        writeoffStatus: "wei_he_xiao:writeoff_status",
      };
    },
    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      ////console.log(e);
      var arr = [];
      this.changList = e;
      for (var i = 0; i < e.length; i++) {
        arr.push(e[i].financeInId);
      }
      this.ids = arr;
    },
    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
    // 上一页
    handlePrevPage() {
      this.pageNum = this.pageNum - 1 || 1;
      this.getData();
    },
    // 下一页
    handleNextPage() {
      this.pageNum = this.pageNum + 1 || 1;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.saveBtn {
  margin: 0 auto;
  background-color: #f0a261;
  color: #fff;
  padding: 10px 20px;
}
</style>
